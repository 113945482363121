import React, { useEffect, useState } from 'react';
// import Image from 'next/image';
// import BannerBackground from '../../publicimages_p/banner-img.png';
import styles from './slider.module.css';

const Hero = ({ isMobileView }: any) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = 3;
  const slideDuration = 10; // Duration for each slide in seconds
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    let animationTimeout: any;
    let slideTimeout = setTimeout(() => {
      setIsAnimating(true); // Start the exit animation
      animationTimeout = setTimeout(() => {
        setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        // setActiveSlide(2);
        setIsAnimating(false); // End the exit animation, start the enter animation
      }, 800); // This timeout should match your CSS animation duration
    }, slideDuration * 1000); // Start the exit animation a bit before changing slides

    return () => {
      clearTimeout(slideTimeout);
      clearTimeout(animationTimeout);
    };
  }, [activeSlide]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const safari = /^((?!chrome|android).)*safari/i.test(userAgent);
    setIsSafari(safari);
  }, []);

  const handleProgressBarClick = (index: number) => {
    setActiveSlide(index); // Set active slide to the clicked index
  };

  const handleRedirect = () => {
    window.location.href = 'https://app.primetrader.com/auth/signup';
  };

  return (
    <>
      {isMobileView ? (
        <section className='mob-hero'>
          <div className='liner-shade'>
            <div className='mob-container'>
              <div className='hero-wrapper' id='overview'>
                <div className='banner-img'>
                  <img
                    src={'/images_p/Hero/hero_img.webp'}
                    alt='banner-img'
                    fetchpriority='high'
                  />
                </div>
                <div className='txt-box'>
                  <h1 className='text-glow'>PrimeTrader</h1>

                  <div className={styles['es-slider']}>
                    <div className={styles['es-slides-container']}>
                      {/* Slide 1 */}
                      <div
                        key={0}
                        className={`slide ${
                          0 === activeSlide
                            ? isAnimating
                              ? styles['es-slide-exit']
                              : styles['es-slide-enter']
                            : ''
                        }`}
                        style={{
                          display: activeSlide === 0 ? 'block' : 'none'
                        }}
                      >
                        <div className='content-box'>
                          <h4>Unlock Web3 with a Single Click</h4>
                          <p>
                            With our intuitive Smart Wallet, transition between
                            fiat and Web3 in one click. Simple, fast, and secure
                            - experience decentralized finance without the
                            hassle.
                          </p>
                        </div>
                      </div>

                      {/* Slide 2 */}
                      <div
                        key={1}
                        className={`slide ${
                          1 === activeSlide
                            ? isAnimating
                              ? styles['es-slide-exit']
                              : styles['es-slide-enter']
                            : ''
                        }`}
                        style={{
                          display: activeSlide === 1 ? 'block' : 'none'
                        }}
                      >
                        <div className='content-box'>
                          <h4>Effortless Investing, Maximum Growth</h4>
                          <p>
                            Forget the complexity. Choose your portfolio,
                            co-invest with experts, and watch your assets grow.
                            One click is all it takes to dive into the future of
                            finance.
                          </p>
                        </div>
                      </div>

                      {/* Slide 3 */}
                      <div
                        key={2}
                        className={`slide ${
                          2 === activeSlide
                            ? isAnimating
                              ? styles['es-slide-exit']
                              : styles['es-slide-enter']
                            : ''
                        }`}
                        style={{
                          display: activeSlide === 2 ? 'block' : 'none'
                        }}
                      >
                        <div className='content-box'>
                          <h4>Lead the Future of Asset Management</h4>
                          <p>
                            Launch on-chain portfolios, manage millions, and
                            earn like Wall Street. Empower communities to build
                            wealth while shaping the future of finance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`slider-bar ${isSafari ? 'safari' : ''}`}>
                    <div className={styles['es-progress-bars-container']}>
                      {Array.from({ length: totalSlides }).map((_, index) => (
                        <div
                          key={index}
                          className={styles['es-progress-bar-container']}
                          onClick={() => handleProgressBarClick(index)}
                        >
                          <div
                            className={`${styles['es-progress-bar']} ${
                              index === activeSlide
                                ? styles['es-slide-active']
                                : styles['es-slide-inactive']
                            }`}
                            style={{
                              animationDuration: `${
                                index === activeSlide ? slideDuration : 0
                              }s`
                            }}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <a
                    href='https://app.primetrader.com/auth/signup/'
                    className='banner-btn'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className='main-banner' id='overview'>
          <div className='liner-shade'>
            <div className='container'>
              <div
                className='txt-box'
                style={{
                  scale: 0.8,
                  marginLeft: -63
                }}
              >
                <div className='content-box' style={{ marginBottom: 28 }}>
                  <h1
                    style={{
                      letterSpacing: '3px',
                      fontWeight: '500',
                      textShadow: '0px 0px 25px #91EBFF'
                    }}
                  >
                    PrimeTrader
                  </h1>
                </div>
                <div className={styles['es-slider']}>
                  <div className={styles['es-slides-container']}>
                    {/* Slide 1 */}
                    <div
                      key={0}
                      className={`slide ${
                        0 === activeSlide
                          ? isAnimating
                            ? styles['es-slide-exit']
                            : styles['es-slide-enter']
                          : ''
                      }`}
                      style={{
                        display: activeSlide === 0 ? 'block' : 'none'
                      }}
                    >
                      <div className='content-box'>
                        <h4>Unlock Web3 with a Single Click</h4>
                        <p>
                          With our intuitive Smart Wallet, transition between
                          fiat and Web3 in one click. Simple, fast, and secure -
                          experience decentralized finance without the hassle.
                        </p>
                      </div>
                    </div>

                    {/* Slide 2 */}
                    <div
                      key={1}
                      className={`slide ${
                        1 === activeSlide
                          ? isAnimating
                            ? styles['es-slide-exit']
                            : styles['es-slide-enter']
                          : ''
                      }`}
                      style={{
                        display: activeSlide === 1 ? 'block' : 'none'
                      }}
                    >
                      <div className='content-box'>
                        <h4>Effortless Investing, Maximum Growth</h4>
                        <p>
                          Forget the complexity. Choose your portfolio,
                          co-invest with experts, and watch your assets grow.
                          One click is all it takes to dive into the future of
                          finance.
                        </p>
                      </div>
                    </div>

                    {/* Slide 3 */}
                    <div
                      key={2}
                      className={`slide ${
                        2 === activeSlide
                          ? isAnimating
                            ? styles['es-slide-exit']
                            : styles['es-slide-enter']
                          : ''
                      }`}
                      style={{
                        display: activeSlide === 2 ? 'block' : 'none'
                      }}
                    >
                      <div className='content-box'>
                        <h4>Lead the Future of Asset Management</h4>
                        <p>
                          Launch on-chain portfolios, manage millions, and earn
                          like Wall Street. Empower communities to build wealth
                          while shaping the future of finance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`slider-bar ${isSafari ? 'safari' : ''}`}>
                  <div className={styles['es-progress-bars-container']}>
                    {Array.from({ length: totalSlides }).map((_, index) => (
                      <div
                        key={index}
                        className={styles['es-progress-bar-container']}
                        onClick={() => handleProgressBarClick(index)}
                      >
                        <div
                          className={`${styles['es-progress-bar']} ${
                            index === activeSlide
                              ? styles['es-slide-active']
                              : styles['es-slide-inactive']
                          }`}
                          style={{
                            animationDuration: `${
                              index === activeSlide ? slideDuration : 0
                            }s`
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className='LaunchNavBtn'
                  style={{
                    width: '244px',
                    marginTop: '50px'
                  }}
                  onClick={handleRedirect}
                >
                  <label style={{ fontSize: '18px' }}>Get Started</label>
                </div>
              </div>
              <div className='banner-img'>
                <img
                  src={'/images_p/Hero/hero_img.webp'}
                  alt='banner-img'
                  loading='lazy'
                  className='hand'
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Hero;
