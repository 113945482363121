'use client';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import Navbar from '@/components/Navbar/Navbar';
import Hero from '@/components/HeroSection/Hero';
import HowToTrade from '@/components/HowToPrimeTrade/HowToTrade';
import MeetTrading from '@/components/MeetTrading';
import EcoGettingStarted from '@/components/GettingStartedWithBridging/EcoGettingStarted';
import EcoSystem from '@/components/EcoSystem';
import RoadMap from '@/components/RoadMap/RoadMap';
import ReadyTrade from '@/components/ReadyTrade';
import Footer from '@/components/Footer/Footer';

const CarouselTeam = lazy(
  () => import('@/components/CoreTeamPinnedWheel/CoreTeamPinnedWheel')
);
import Avatar3D from '@/components/Avatar3d';
import PtNumbers from '@/components/PtNumbers/PtNumbers';
import ContactUs from '@/components/ContactUs/Contactus';
import MediaWriteAboutUs from '@/components/MediaWriteAboutUs/MediaWriteAboutUs';
import LeadFutureFinance from '@/components/LeadFutureFinance/LeadFutureFinance';
import Loader from '@/components/Loader';
import BecomePrimeTraderAmbassador from '@/components/BecomePrimeTraderAmbassador/BecomePrimeTraderAmbassador';
import PrimeTraderApp from '@/components/PrimeTraderApp/PrimeTraderApp';
import AiPoweredMarketUpdates from '@/components/AiPoweredMarketUpdates/AiPoweredMarketUpdates';
import Advisors from '@/components/Advisors/Advisors';
import { FAQ } from '@/components/FAQ/FAQ';
import { Opportunity } from '@/components/Opportunity/Opportunity';
import AiPoweredTrading from '@/components/AiPoweredTrading/AiPoweredTrading';
import GameFi from '@/components/GameFi/GameFi';
import OurPartners from '@/components/OurPartners/OurPartners';
import TestimonialsSection from '@/components/Testimonials/index';

export default function Home() {
  if (typeof window !== 'undefined') {
    console.log('do nothing');
  } else {
    return;
  }
  const [isMobileView, setIsMobileView] = useState(
    window.matchMedia('(max-width: 767px)').matches ? true : false
  );
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
      document.body.style.visibility = 'visible';
    }

    // handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Function to scroll to the hash section
    const scrollToHash = () => {
      if (window.location.hash) {
        const id = window.location.hash.substring(1);
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    scrollToHash();

    window.addEventListener('hashchange', scrollToHash);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };
  }, []);

  return (
    <main>
      <section className='top-section'>
        <div className='liner-shade'>
          {/* <HalvingCountdown
            targetDate={new Date('2024-05-08T18:00:00Z')}
            isMobileView={isMobileView}
          /> */}
          <div className='container'>
            <Navbar isMobileView={isMobileView} />
          </div>
        </div>
      </section>
      <Hero isMobileView={isMobileView} />
      <PtNumbers />
      <HowToTrade isMobileView={isMobileView} />
      <LeadFutureFinance />
      <BecomePrimeTraderAmbassador />
      <PrimeTraderApp />
      <Opportunity />
      {/* <MeetTrading isMobileView={isMobileView} /> */}
      <EcoSystem isMobileView={isMobileView} />
      <AiPoweredMarketUpdates />
      <AiPoweredTrading />
      <GameFi />
      {/* <EcoGettingStarted isMobileView={isMobileView} /> */}
      <RoadMap isMobileView={isMobileView} />
      {/*<MediaWriteAboutUs isMobileView={isMobileView} />*/}
      <OurPartners />
      <TestimonialsSection isMobileView={isMobileView} />
      {/* <Avatar3D isMobileView={isMobileView} /> */}
      {/* <ReadyTrade isMobileView={isMobileView} /> */}
      <Suspense fallback={<div>Loading...</div>}>
        <CarouselTeam isMobileView={isMobileView} />
      </Suspense>
      <Advisors />
      <FAQ />
      <Footer isMobileView={isMobileView} />
      <Loader isLoading={isLoading} />
    </main>
  );
}
