import { useMediaQuery } from '@uidotdev/usehooks';
import React from 'react';
interface TestimonialProps {
  name: string;
  title: string;
  quote: string;
  imageSrc: string;
}

export const TestimonialCard: React.FC<TestimonialProps> = ({
  name,
  title,
  quote,
  imageSrc
}) => {
  const cardStyle: React.CSSProperties = {
    height: 390,
    width: 350,
    position: 'relative',
    margin: 10
  };

  const mobileCardStyle: React.CSSProperties = {
    height: 340,
    width: 300,
    position: 'relative',
    margin: 10
  };

  const mirrorCardStyle: React.CSSProperties = {
    ...cardStyle,
    position: 'absolute',
    top: 20,
    left: 10,
    zIndex: 0
  };

  const contentStyle: React.CSSProperties = {
    position: 'relative',
    height: '100%',
    padding: 24,
    borderRadius: 12,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    background: `url(/images/testimonial-desktop.png) no-repeat fixed`,
    backgroundSize: 'contain',
    backgroundBlendMode: 'overlay, normal',
    backdropFilter: 'blur(40px)'
  };

  const mirrorContentStyle: React.CSSProperties = {
    ...contentStyle,
    background: `url(/images/testimonials-mirror.png) no-repeat fixed`,
    backgroundSize: '95%',
    backgroundPosition: 'right'
  };

  const mobileContentStyle: React.CSSProperties = {
    position: 'relative',
    height: '100%',
    padding: 24,
    borderRadius: 24,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    background: `url(/images/testimonial-mobile.png) no-repeat fixed`,
    backgroundSize: 'contain',
    backgroundBlendMode: 'overlay, normal',
    backdropFilter: 'blur(40px)'
  };

  const profileStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16
  };

  const imageContainerStyle: React.CSSProperties = {
    width: 94,
    height: 94,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(135deg, #6b46c1, #3182ce)',
    transform: 'translateX(20%) translateY(20%)'
  };

  const mobileImageContainerStyle: React.CSSProperties = {
    ...imageContainerStyle,
    transform: 'translateX(10%) translateY(0%)',
    minWidth: 82,
    minHeight: 82,
    height: 82,
    width: 82,
    aspectRatio: '1 / 1'
  };

  const imageStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%'
  };

  const infoStyle: React.CSSProperties = {
    textAlign: 'right'
  };

  const nameStyle: React.CSSProperties = {
    color: '#E6E6E6',
    fontSize: 22,
    fontWeight: 500,
    margin: 0,
    paddingLeft: 30
  };

  const titleStyle: React.CSSProperties = {
    color: '#E6E6E6',
    fontSize: 12,
    margin: 0
  };

  const mobileQuoteStyle: React.CSSProperties = {
    color: '#e2e8f0',
    fontSize: 14,
    lineHeight: 1.5,
    flexGrow: 1,
    overflowY: 'auto',
    textAlign: 'left',
    padding: '10px 0'
  };

  const quoteStyle: React.CSSProperties = {
    ...mobileQuoteStyle,
    paddingTop: '35px'
  };

  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)');

  return (
    <div style={isSmallDevice ? mobileCardStyle : cardStyle}>
      {!isSmallDevice && (
        <div style={mirrorCardStyle}>
          <div
            style={{
              ...mirrorContentStyle,
              backdropFilter: 'none',
              borderRadius: 24
            }}
          >
            <div style={profileStyle}>
              <div style={imageContainerStyle}>
                <img src={imageSrc} alt={name} style={imageStyle} />
              </div>
              <div style={infoStyle}>
                <h2 style={nameStyle}>{name}</h2>
                <p style={titleStyle}>{title}</p>
              </div>
            </div>
            <p style={quoteStyle}>{quote}</p>
          </div>
        </div>
      )}
      <div style={isSmallDevice ? mobileContentStyle : contentStyle}>
        {/* <div style={borderStyle}></div> */}
        <div style={profileStyle}>
          <div
            style={
              isSmallDevice ? mobileImageContainerStyle : imageContainerStyle
            }
          >
            <img src={imageSrc} alt={name} style={imageStyle} />
          </div>
          <div style={infoStyle}>
            <h2 style={nameStyle}>{name}</h2>
            <p style={titleStyle}>{title}</p>
          </div>
        </div>
        <p style={isSmallDevice ? mobileQuoteStyle : quoteStyle}>{quote}</p>
      </div>
    </div>
  );
};
