import React from 'react';
import WaveAnimation from './WaveAnimation';
import style from './style.module.scss';

const LeadFutureFinance = () => {
  return (
    <section className={style.leadFutureFinanceSection}>
      <div className={style.headerSection}>
        <WaveAnimation />
        <label>Lead Future Finance</label>
        <p>
          PrimeTrader empowers crypto experts to shape the next era of
          decentralized finance.
        </p>
      </div>
      <div className={style.content}>
        <div className={style.cardWrapper}>
          <div className={style.card}>
            <label>Launch On-Chain Funds</label>
            <p>
              Create and manage on-chain portfolios effortlessly, enabling
              seamless co-investments.
            </p>
          </div>
        </div>
        <div className={style.cardWrapper}>
          <div className={style.card}>
            <label>Earn Like a Pro</label>
            <p>
              Unlock new revenue streams with management and performance fees,
              just like Hedge Fund Managers.
            </p>
          </div>
        </div>
        <div className={style.cardWrapper}>
          <div className={style.card}>
            <label>Empower Wealth</label>
            <p>
              Help others grow their wealth through co-investing. You’re not
              just managing funds - you’re driving real-world impact.
            </p>
          </div>
        </div>
      </div>
      <div className={style.sectionFooter}>
        <label>
          Turn your expertise into a force for global wealth creation with
          PrimeTrader.
        </label>
      </div>
    </section>
  );
};

export default LeadFutureFinance;
