import React from 'react';
import style from './style.module.scss';

// Data
import AdvisorsData from '@/components/Advisors/AdvisorsData';

const Advisors = () => {
  return (
    <section className={style.advisorsSection}>
      <div className={style.headerSection}>
        <label>Advisors</label>
      </div>
      <div className={style.cardsSection}>
        {AdvisorsData.map((advisor) => (
          <div key={advisor.id} className={style.card}>
            <div className={style.cardContent}>
              <div className={style.imageContainer}>
                <img
                  src={advisor.img}
                  alt={advisor.imgAlt}
                  className='responsive pt-responsive'
                  loading='eager'
                />
              </div>
              <div className={style.textContainer}>
                <div className={style.topText}>
                  <label>{advisor.name}</label>
                  <p>{advisor.role}</p>
                </div>
                <div className={style.bottomText}>
                  <p>{advisor.affiliatedCompany}</p>
                </div>
              </div>
              <div className={style.socialLinksContainer}>
                <a href={advisor.social.linkedin}>
                  <img src='/images_p/core_team/linkedin.svg' alt='linkedin' />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Advisors;
