import React from 'react';
import style from './style.module.scss';

const AiPoweredMarketUpdates = () => {
  return (
    <section className={style.aiPoweredMarketUpdatesSection} id='MarketUpdates'>
      <div className={style.sectionBackground} id='MarketUpdates'>
        <div className={style.sectionContent}>
          <div className={style.sectionHeader}>
            <label>AI-Powered Market Updates: Stay Ahead of the Curve</label>
            <p>
              PrimeTrader harnesses the power of AI to detect and deliver the
              latest trends and the most relevant news for traders. From
              altcoins to gaming, DeFi to NFTs, our AI ensures you’re always
              informed and one step ahead in the ever-evolving world of crypto.
            </p>
          </div>
          <div className={style.cardsWrapper}>
            <div className={style.leftCard}>
              <label>The Power of Daily Live News</label>
              <img
                src={'/images_p/AiPoweredMarketUpdates/pt_news_1.webp'}
                alt='pt-news-1'
                loading='eager'
              />
              <p>
                Stay ahead with real-time updates on market trends and more.
              </p>
              <div className={style.clickHereButton}>
                <a href='https://app.primetrader.com/auth/signup/'>
                  <label>Click here for Daily Live News</label>
                </a>
              </div>
              <p>
                Visit for exclusive content and <br /> learn-to-earn
                opportunities.
              </p>
            </div>
            <div className={style.rightCard}>
              <label>The Weekly Newsletter Stay Informed</label>
              <img
                src={'/images_p/AiPoweredMarketUpdates/prime_alpha_icon_1.webp'}
                alt='prime-alpha-icon-1'
                loading='eager'
              />
              <p>Get the big picture with our AI-powered newsletter.</p>
              <a href='/newsletter' className={style.primeAlphaButton}>
                <img
                  src={
                    '/images_p/AiPoweredMarketUpdates/prime_alpha_button.webp'
                  }
                  alt='prime_alpha_button'
                  loading='eager'
                />
              </a>
              <p>
                Visit for exclusive content and <br /> learn-to-earn
                opportunities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AiPoweredMarketUpdates;
