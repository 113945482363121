// type defination
export interface TeamMember {
  id: number;
  name: string;
  role: string;
  affiliatedCompany: string;
  img: string;
  imgAlt: string;
  social: {
    linkedin: string;
  };
}

const AdvisorsData: TeamMember[] = [
  {
    id: 1,
    name: 'Dr. Andrea Bracciali',
    role: 'Blockchain Advisor',
    affiliatedCompany: 'Università degli Studi di Torino',
    img: '/images_p/Advisors/AdreaBracciali.webp',
    imgAlt: 'Dr. Andrea Bracciali',
    social: {
      linkedin: 'https://www.linkedin.com/in/andrea-bracciali-lkdn/'
    }
  },
  {
    id: 2,
    name: 'Dr. Reto Luthiger',
    role: 'Blockchain Legal Advisor',
    affiliatedCompany: 'MLL Legal',
    img: '/images_p/Advisors/RetoLuthiger.webp',
    imgAlt: 'Dr. Reto Luthiger',
    social: {
      linkedin: 'https://www.linkedin.com/in/reto-luthiger-63694530/'
    }
  },
  {
    id: 3,
    name: 'Marco Grendel',
    role: 'Community & Ecosystem Advisor',
    affiliatedCompany: 'Polygon Labs',
    img: '/images_p/Advisors/MarcoGrendel.webp',
    imgAlt: 'Marco Grendel',
    social: {
      linkedin: 'https://www.linkedin.com/in/marco-moshi/'
    }
  },
  {
    id: 4,
    name: 'Jose Zamorano',
    role: 'Strategy Advisor',
    affiliatedCompany: 'Halborn',
    img: '/images_p/Advisors/JoseZamorano.webp',
    imgAlt: 'Jose Zamorano',
    social: {
      linkedin: 'https://www.linkedin.com/in/jose-luis-zamorano/'
    }
  },
  {
    id: 5,
    name: 'Felipe Gallino',
    role: 'Exchange Advisor',
    affiliatedCompany: 'BYBIT',
    img: '/images_p/Advisors/FelipeGallino.webp',
    imgAlt: 'Felipe Gallino',
    social: {
      linkedin: 'https://www.linkedin.com/in/felipegallino/'
    }
  },
  {
    id: 6,
    name: 'Mario Olivares',
    role: 'Fintech Legal Advisor',
    affiliatedCompany: 'FinReg360',
    img: '/images_p/Advisors/MariaOlivares.webp',
    imgAlt: 'Maria Olivares',
    social: {
      linkedin: 'https://www.linkedin.com/in/jmolivares-financialregulation/'
    }
  },
  {
    id: 7,
    name: 'Dr. Stefan Schroter',
    role: 'IP & Trademark Advisor',
    affiliatedCompany: 'MLL Legal',
    img: '/images_p/Advisors/StefanSchroter.webp',
    imgAlt: 'Stefan Schroter',
    social: {
      linkedin: 'https://www.linkedin.com/in/stefan-schr%C3%B6ter-444981104/'
    }
  },
  {
    id: 8,
    name: 'Patrick Barile',
    role: 'Gaming & Strategy Advisor',
    affiliatedCompany: 'DappRadar',
    img: '/images_p/Advisors/PatrickBarile.webp',
    imgAlt: 'Patrick Barile',
    social: {
      linkedin: 'https://www.linkedin.com/'
    }
  },
  {
    id: 9,
    name: 'Fabrizio La Vita',
    role: 'Launchpad & Growth Advisor',
    affiliatedCompany: 'Acid Game Studio',
    img: '/images_p/Advisors/FabrizioLaVita.webp',
    imgAlt: 'Fabrizio La Vita',
    social: {
      linkedin: 'https://www.linkedin.com/in/fabrizio-la-vita/'
    }
  },
  {
    id: 10,
    name: 'Geoffrey Bonnechere',
    role: 'Marketing Advisor',
    affiliatedCompany: 'Reflex Media Group',
    img: '/images_p/Advisors/GeofferyBonnechere.webp',
    imgAlt: 'Geoffrey Bonnechere',
    social: {
      linkedin: 'https://www.linkedin.com/in/trafficgeoff/'
    }
  }
];

export default AdvisorsData;
