import styles from './styles.module.scss';
import React from 'react';

export const Opportunity = () => {
  return (
    <div className={styles.opportunitySection}>
      <div className={styles.sectionContent}>
        <div className={styles.sectionHeader}>
          <label>Opportunity We See</label>
          <p>
            We empower over 1 billion people, from beginners to pros, to unlock
            the potential of cryptocurrency and decentralized finance. With our
            seamless and intuitive platform, we’re transforming the way people
            invest.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img src={`/images_p/Opportunity/card1.webp`} alt='card1' />
          <img src={`/images_p/Opportunity/card2.webp`} alt='card2' />
          <img src={`/images_p/Opportunity/card3.webp`} alt='card3' />
        </div>
        <div className={styles.sectionFooter}>
          <p>
            Our bold vision: 5 million users onboarded into our ecosystem in the
            next 5 years, managing over $10 billion in on-chain assets.
          </p>
        </div>
      </div>
    </div>
  );
};
