'use client';
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem
} from '@/components/Carousel';
import MobileTestimonials from '@/components/Testimonials/mobileTestimonials';
import { TestimonialCard } from '@/components/Testimonials/testimonial-card';
import { testimonials } from '@/components/Testimonials/testiomnials';
import { useMediaQuery } from '@uidotdev/usehooks';
import React from 'react';

const TestimonialsSection = ({ isMobileView }: any) => {
  const isMediumDevice = useMediaQuery(
    'only screen and (min-width : 769px) and (max-width : 992px)'
  );
  const isLargeDevice = useMediaQuery(
    'only screen and (min-width : 993px) and (max-width : 1200px)'
  );
  const isExtraLargeDevice = useMediaQuery(
    'only screen and (min-width : 1201px) and (max-width : 1440px)'
  );

  return (
    <>
      {isMobileView ? (
        <section className='mob-testimonials-section'>
          <div className='liner-shade'>
            <div className='container'>
              <h3 style={{ textShadow: '0px 0px 20px #45d3e8' }}>
                Testimonials
              </h3>
              <MobileTestimonials />
            </div>
          </div>
        </section>
      ) : (
        <section
          style={{
            minHeight: '550px',
            background: `url('/images_p/HowPTWork/bg.webp')`, // Use backticks or single quotes for the URL
            backgroundSize: '100% 100%', // Use camelCase
            backgroundRepeat: 'no-repeat' // Use camelCase
          }}
          className='mob-testimonials-section'
        >
          <div
            className='container liner-shade'
            style={{ textAlign: 'center' }}
          >
            <h3
              style={{
                marginTop: 0,
                marginBottom: '50px',
                textShadow: '0px 0px 20px #45d3e8'
              }}
            >
              Testimonials
            </h3>

            <Carousel>
              <CarouselContent>
                {testimonials.map((testimonial, index) => (
                  <CarouselItem
                    key={index}
                    flexBasis={
                      isMediumDevice
                        ? '50%'
                        : isLargeDevice
                        ? '40%'
                        : isExtraLargeDevice
                        ? '35%'
                        : '27%'
                    }
                  >
                    <TestimonialCard key={index} {...testimonial} />
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselDots />
            </Carousel>
          </div>
        </section>
      )}
    </>
  );
};

export default TestimonialsSection;
