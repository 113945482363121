import React from 'react';
import style from './style.module.scss';

const OurPartners = () => {
  return (
    <section className={style.ourPartners}>
      <div className={style.sectionHeader}>
        <label>Our partners</label>
      </div>

      <div className={style.sectionContent}>
        <img
          src={'/images_p/OurPartners/polygon_blockchain_logo2_1.webp'}
          alt='polygon_blockchain_logo2_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/binance_logo_wine_1.webp'}
          alt='binance_logo_wine_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/arbitrum_arb_logo2_1.webp'}
          alt='arbitrum_arb_logo2_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/chainlink_logo.webp'}
          alt='chainlink_logo'
          loading='eager'
        />
        <img
          src={
            '/images_p/OurPartners/halborn_blockchain_security_logo_vector_1.webp'
          }
          alt='halborn_blockchain_security_logo_vector_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/dapp_radar_2_1.webp'}
          alt='dapp_radar_2_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/black_tokenomics_1.webp'}
          alt='black_tokenomics_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/bitx_1.webp'}
          alt='bitx_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/emiratesnbd_ae_1.webp'}
          alt='emiratesnbd_ae_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/google_pay_logo_svg_1.webp'}
          alt='google_pay_logo_svg_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/bbva_2019_svg_1.webp'}
          alt='bbva_2019_svg_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/finreg360_logo_1.webp'}
          alt='finreg360_logo_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/galxe_1.webp'}
          alt='galxe_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/tradingview_2_1.webp'}
          alt='tradingview_2_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/group_1991422175.webp'}
          alt='group_1991422175'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/rzlt_wide_1_1.webp'}
          alt='rzlt_wide_1_1'
          loading='eager'
        />
        <img
          src={
            '/images_p/OurPartners/coingecko_logo_3fb33f3b5a_seeklogo_com_1.webp'
          }
          alt='coingecko_logo_3fb33f3b5a_seeklogo_com_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/alchemy_1.webp'}
          alt='alchemy_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/enzyme_mln8469_1.webp'}
          alt='enzyme_mln8469_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/walletconnect_1.webp'}
          alt='walletconnect_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/gsr_1.webp'}
          alt='gsr_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/mll_1.webp'}
          alt='mll_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/bga_blue_1.webp'}
          alt='bga_blue_1'
          loading='eager'
        />
        <img
          src={'/images_p/OurPartners/easy_payment_finance_2_1.webp'}
          alt='easy_payment_finance_2_1'
          loading='eager'
        />
      </div>
    </section>
  );
};

export default OurPartners;
