import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import styles from './es_slider.module.css';

const HowToTrade = ({ isMobileView }) => {
  return isMobileView ? <MobileView /> : <DesktopViewWithoutBootstrap />;
};

const MobileView = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = 3;
  const slideDuration = 10; // Duration for each slide in seconds
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    let animationTimeout;
    let slideTimeout = setTimeout(() => {
      setIsAnimating(true); // Start the exit animation
      animationTimeout = setTimeout(() => {
        setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        // setActiveSlide(2);
        setIsAnimating(false); // End the exit animation, start the enter animation
      }, 800); // This timeout should match your CSS animation duration
    }, slideDuration * 1000); // Start the exit animation a bit before changing slides

    return () => {
      clearTimeout(slideTimeout);
      clearTimeout(animationTimeout);
    };
  }, [activeSlide]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const safari = /^((?!chrome|android).)*safari/i.test(userAgent);
    setIsSafari(safari);
  }, []);

  const handleProgressBarClick = (index) => {
    setActiveSlide(index); // Set active slide to the clicked index
  };

  return (
    <>
      <section className='how-prime-section_mob' id='HowItWork'>
        <div className='liner-shade'>
          <div className='spider-graph'>
            <div className='content'>
              <div className='mainHeading'>
                <label>How PrimeTrader Works: The 3-Click Revolution</label>
                <p>
                  With PrimeTrader, it’s just three clicks to the future of
                  finance.
                </p>
              </div>
              <div className='stepsCount'>
                <div className={`slider-bar ${isSafari ? 'safari' : ''}`}>
                  <div className={styles['es-progress-bars-container']}>
                    {Array.from({ length: totalSlides }).map((_, index) => (
                      <div
                        key={index}
                        className={styles['es-progress-bar-container']}
                        onClick={() => handleProgressBarClick(index)}
                      >
                        <div
                          className={`${styles['es-progress-bar']} ${
                            index === activeSlide
                              ? styles['es-slide-active']
                              : styles['es-slide-inactive']
                          }`}
                          style={{
                            animationDuration: `${
                              index === activeSlide ? slideDuration : 0
                            }s`
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles['es-slider']}>
                <div className={styles['es-slides-container']}>
                  {/* Slide 1 */}
                  <div
                    key={0}
                    className={`slide ${
                      0 === activeSlide
                        ? isAnimating
                          ? styles['es-slide-exit']
                          : styles['es-slide-enter']
                        : ''
                    }`}
                    style={{
                      display: activeSlide === 0 ? 'block' : 'none'
                    }}
                  >
                    <div className='content-box' style={{ marginRight: 10 }}>
                      <img
                        src='/images_p/HowPTWork/slide1.webp'
                        alt='newmob'
                        style={{
                          minHeight: '315px',
                          minWidth: '360px',
                          maxWidth: '373px'
                        }}
                      />
                      <div className='titlePoints'>
                        <div>
                          <label>One-Click to Web3</label>
                          <p>
                            Bridge CeFi and DeFi instantly with our VASP-powered
                            Smart Wallet.
                          </p>
                        </div>
                        <div>
                          <label>Fast-Track to DeFi</label>
                          <p>
                            Virtual cards and IBAN accounts—PrimeTrader is your
                            all-in-one financial gateway.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Slide 2 */}
                  <div
                    key={1}
                    className={`slide ${
                      1 === activeSlide
                        ? isAnimating
                          ? styles['es-slide-exit']
                          : styles['es-slide-enter']
                        : ''
                    }`}
                    style={{
                      display: activeSlide === 1 ? 'block' : 'none'
                    }}
                  >
                    <div className='content-box'>
                      <img
                        src='/images_p/HowPTWork/slide2.webp'
                        alt='newmob'
                        style={{
                          minHeight: '317px',
                          minWidth: '360px',
                          maxWidth: '360px'
                        }}
                      />
                      <div className='titlePoints'>
                        <div>
                          <label>Instant Funding</label>
                          <p>
                            Deposit with Google Pay or your card in
                            seconds—fast, secure, seamless.
                          </p>
                        </div>
                        <div>
                          <label>Total Control</label>
                          <p>
                            Effortlessly fund with fiat or crypto, anytime,
                            anywhere.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Slide 3 */}
                  <div
                    key={2}
                    className={`slide ${
                      2 === activeSlide
                        ? isAnimating
                          ? styles['es-slide-exit']
                          : styles['es-slide-enter']
                        : ''
                    }`}
                    style={{
                      display: activeSlide === 2 ? 'block' : 'none'
                    }}
                  >
                    <div className='content-box'>
                      <img
                        src='/images_p/HowPTWork/slide3.webp'
                        alt='newmob'
                        style={{
                          minHeight: '335px',
                          minWidth: '360px',
                          maxWidth: '388px'
                        }}
                      />
                      <div className='titlePoints'>
                        <div>
                          <label>Invest Smarter</label>
                          <p>
                            PrimeTrader and experts manage your crypto portfolio
                            while you stay in control.
                          </p>
                        </div>
                        <div>
                          <label>Own the Future</label>
                          <p>
                            Invest in real estate, gold, and tokenized assets
                            worldwide—starting from just $10.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DesktopViewWithoutBootstrap = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = 3;
  const slideDuration = 9.8;
  const [isSafari, setIsSafari] = useState(false);
  const slides = ['Create', 'Deposit', 'Invest'];

  // useEffect(() => {
  //   let animationTimeout;
  //   let slideTimeout = setTimeout(() => {
  //     setIsAnimating(true);
  //     animationTimeout = setTimeout(() => {
  //       setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  //       setIsAnimating(false);
  //     }, 800);
  //   }, slideDuration * 1000);

  //   return () => {
  //     clearTimeout(slideTimeout);
  //     clearTimeout(animationTimeout);
  //   };
  // }, [activeSlide]);

  // useEffect(() => {
  //   setActiveSlide(1);
  //   const userAgent = navigator.userAgent;
  //   const safari = /^((?!chrome|android).)*safari/i.test(userAgent);
  //   setIsSafari(safari);
  // }, []);

  const handleProgressBarClick = (index) => {
    setActiveSlide(index);
  };

  return (
    <>
      <div className={style.HowPTWorkSection} id='HowItWork'>
        <div className={style.headerWrapper}>
          <label className={style.heading}>
            How PrimeTrader Works: The 3-Click Revolution
          </label>
          <label className={style.subheading}>
            With PrimeTrader, it’s just three clicks to the future of finance.
          </label>
          <div className={style.barsWrapper}>
            {slides.map((slide, index) => (
              <div
                className={style.barButton}
                onClick={() => handleProgressBarClick(index)}
              >
                <label>{slide}</label>
                <div
                  key={index}
                  className={`${style.bar} ${
                    index === activeSlide && style.activeBar
                  }`}
                >
                  <div className={style.loadingBar} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={style.slider} style={{ scale: 0.9 }}>
          <div className='slider-without-bt'>
            <div
              key={0}
              style={{
                display: activeSlide === 0 ? 'block' : 'none'
              }}
              className={`slide ${
                0 === activeSlide
                  ? isAnimating
                    ? styles['es-slide-exit']
                    : styles['es-slide-enter']
                  : ''
              }`}
            >
              <div className={style.slide}>
                <div className={style.left}>
                  <img
                    src='/images_p/HowPTWork/slide1.webp'
                    alt='one click to web3'
                  />
                </div>

                <div className={style.right}>
                  <div className={style.con}>
                    <div>
                      <label className={style.subheading}>
                        Integrated Finance
                      </label>
                      <p className={style.content}>
                        Bridge CeFi and DeFi instantly with our VASP-powered
                        Smart Wallet.
                      </p>
                    </div>
                    <div>
                      <label className={style.subheading}>
                        Fast-Track to DeFi
                      </label>
                      <p className={style.content}>
                        Virtual cards and IBAN accounts—PrimeTrader is your
                        all-in-one financial gateway.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              key={1}
              style={{
                display: activeSlide === 1 ? 'block' : 'none'
              }}
              className={`slide ${
                1 === activeSlide
                  ? isAnimating
                    ? styles['es-slide-exit']
                    : styles['es-slide-enter']
                  : ''
              }`}
            >
              <div className={style.slide}>
                <div className={style.left}>
                  <img
                    src='/images_p/HowPTWork/slide2.webp'
                    alt='gamified crypto'
                  />
                </div>

                <div className={style.right}>
                  <div className={style.con}>
                    <div>
                      <label className={style.subheading}>
                        Instant Funding
                      </label>
                      <p className={style.content}>
                        Deposit with Google Pay or your card in seconds—fast,
                        secure, seamless.
                      </p>
                    </div>
                    <div>
                      <label className={style.subheading}>Total Control</label>
                      <p className={style.content}>
                        Effortlessly fund with fiat or crypto, anytime,
                        anywhere.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              key={2}
              style={{
                display: activeSlide === 2 ? 'block' : 'none'
              }}
              className={`slide ${
                2 === activeSlide
                  ? isAnimating
                    ? styles['es-slide-exit']
                    : styles['es-slide-enter']
                  : ''
              }`}
            >
              <div className={style.slide}>
                <div className={style.left}>
                  <img
                    src='/images_p/HowPTWork/slide3.webp'
                    alt='build your investment'
                  />
                </div>

                <div className={style.right}>
                  <div className={style.con}>
                    <div>
                      <label className={style.subheading}>Invest Smarter</label>
                      <p className={style.content}>
                        PrimeTrader and experts manage your crypto portfolio
                        while you stay in control.
                      </p>
                    </div>
                    <div>
                      <label className={style.subheading}>Own the Future</label>
                      <p className={style.content}>
                        Invest in real estate, gold, and tokenized assets
                        worldwide—starting from just $10.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToTrade;
