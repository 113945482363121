import { useEffect, useRef } from 'react';

const WaveAnimation = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let time = 0;
    const speed = 0.005; // Line speed
    const baseAmplitude = 35; // Amplitude for the wave

    const drawWave = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, 'rgba(211, 118, 255, 0)');
      gradient.addColorStop(1, 'rgba(211, 118, 255, 1)');

      ctx.beginPath();

      for (let x = 0; x < canvas.width; x++) {
        const amplitude = baseAmplitude + Math.sin(x * 0.01 + time) * 20;
        const y = canvas.height / 2 + Math.sin(x * 0.02 + time) * amplitude;
        ctx.lineTo(x, y);
      }

      ctx.shadowColor = '#D376FF';
      ctx.shadowBlur = 30;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;

      ctx.lineWidth = 3;
      ctx.strokeStyle = gradient;
      ctx.stroke();
      ctx.closePath();

      ctx.shadowColor = 'transparent';

      time += speed;
      requestAnimationFrame(drawWave);
    };

    drawWave();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <canvas ref={canvasRef} />;
};

export default WaveAnimation;
