import React, { useEffect, useState } from 'react';
import style from './style.module.scss';

const GameFi = () => {
  const [isMobileView, setIsMobileView] = useState(
    window.matchMedia('(max-width: 1200px)').matches ? true : false
  );

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1200) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
      document.body.style.visibility = 'visible';
    }
    // handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={style.gameFi} id='GameFi'>
      <div className={style.contentWrapper}>
        <div className={style.sectionHeader}>
          <label>GameFi</label>
          <div className={style.subheader}>
            <label>Master Your Journey to Wealth</label>
          </div>
          <p>
            PrimeTrader's GameFi experience seamlessly blends trading with
            gaming. Every deposit, trade, and portfolio move boosts your in-game
            progress, unlocking investment tools and higher tiers. Your
            real-world success fuels your game journey, while in-game victories
            unlock even more platform capabilities, including crypto, NFTs, and
            shares, transforming your financial future.
          </p>
        </div>
        {!isMobileView ? (
          <>
            <div className={style.content}>
              <div className={style.articleSection}>
                <img
                  src={'/images_p/GameFi/trader_vs_bull.webp'}
                  alt='trader-vs-bull'
                  loading='eager'
                />
                <div className={style.leftImage}>
                  <label>Crypto Explorers</label>
                  <p>
                    begin their journey by dipping their toes into the crypto
                    world. Here, free trading games offer an exciting, hands-on
                    way to learn the ropes. Progression is swift and victories
                    in trading against the market lead to immediate real
                    rewards, from crypto, NFTs and shares.
                  </p>
                </div>
              </div>
            </div>
            <div className={style.content}>
              <div className={style.articleSection}>
                <div className={style.rightImage}>
                  <label>Crypto Navigators</label>
                  <p>
                    compete by linking in-game progress to real-world wallet
                    activity, trading volume, and investment success. Winning
                    trades unlock powerful in-game advantages, while game
                    achievements unlock exclusive opportunities.
                  </p>
                </div>
                <img
                  src={'/images_p/GameFi/trader_vs_trader.webp'}
                  alt='trader-vs-bull'
                  loading='eager'
                />
              </div>
            </div>
            <div className={style.content}>
              <div className={style.articleSection}>
                <img
                  src={'/images_p/GameFi/trader_euro.webp'}
                  alt='trader-vs-bull'
                  loading='eager'
                />
                <div className={style.leftImage}>
                  <label>Crypto Masters</label>
                  <p>
                    compete in The Champions League of Trading. The most skilled
                    traders compete for prestige, recognition, and high rewards,
                    leveraging their expertise to attract co-investors.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.content}>
              <div className={style.articleSection}>
                <label>Crypto Explorers</label>
                <img
                  src={'/images_p/GameFi/trader_vs_bull.webp'}
                  alt='trader-vs-bull'
                  loading='eager'
                />
                <p>
                  begin their journey by dipping their toes into the crypto
                  world. Here, free trading games offer an exciting, hands-on
                  way to learn the ropes. Progression is swift and victories in
                  trading against the market lead to immediate real rewards,
                  from crypto, NFTs and shares.
                </p>
              </div>
            </div>
            <div className={style.content}>
              <div className={style.articleSection}>
                <label>Crypto Navigators</label>

                <img
                  src={'/images_p/GameFi/trader_vs_trader.webp'}
                  alt='trader-vs-trader'
                  loading='eager'
                />
                <p>
                  compete by linking in-game progress to real-world wallet
                  activity, trading volume, and investment success. Winning
                  trades unlock powerful in-game advantages, while game
                  achievements unlock exclusive opportunities.
                </p>
              </div>
            </div>
            <div className={style.content}>
              <div className={style.articleSection}>
                <label>Crypto Masters</label>
                <img
                  src={'/images_p/GameFi/trader_euro.webp'}
                  alt='trader-euro'
                  loading='eager'
                />
                <p>
                  compete in The Champions League of Trading. The most skilled
                  traders compete for prestige, recognition, and high rewards,
                  leveraging their expertise to attract co-investors.
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GameFi;
