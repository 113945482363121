import React, { useEffect, useState } from 'react';
import style from './style.module.scss';

const BecomePrimeTraderAmbassador = () => {
  const [isMobileView, setIsMobileView] = useState(
    window.matchMedia('(max-width: 1200px)').matches ? true : false
  );

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1200) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
      document.body.style.visibility = 'visible';
    }
    // handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={style.becomePrimeTraderAmbassador}>
      <div className={style.contentWrapper}>
        <div className={style.sectionHeader}>
          <label>Become a PrimeTrader Ambassador</label>
          <p>
            Unlock $10,000 in free capital to launch your trading portfolio.
            Join us today and lead the way in the future of trading.
          </p>
          <div className={style.getStarterdButton}>
            <a href='https://app.primetrader.com/auth/signup/'>
              <label>Get Started Now</label>
            </a>
          </div>
          <label id='InvestmentOpportunities'>
            Discover On-Chain Investment Opportunities:
          </label>
        </div>
        {!isMobileView ? (
          <>
            <div className={style.content}>
              <div className={style.articleSection}>
                <img
                  src={
                    '/images_p/BecomePrimeTraderAmbassador/trader_vs_bull.webp'
                  }
                  alt='trader-vs-bull'
                  loading='eager'
                />
                <div className={style.leftImage}>
                  <label>
                    Crypto Funds:
                    <br />
                    Unleash Your Potential
                  </label>
                  <p>
                    Access high-performing crypto funds curated by industry
                    experts and KOLs. Invest in dynamic strategies that power
                    growth—whether you’re seeking high-risk rewards or stable,
                    secure returns, PrimeTrader has the vault for you.
                  </p>
                </div>
              </div>
            </div>
            <div className={style.content}>
              <div className={style.articleSection}>
                <div className={style.rightImage}>
                  <label>
                    Tokenized Real Estate:
                    <br />
                    Own the Future
                  </label>
                  <p>
                    Invest in premium real estate across the world. From Dubai
                    to New York, own fractions of luxury properties and earn
                    rental income in PrimeTrader Tokens (PTT). Real estate is
                    now at your fingertips—accessible, secure, and global.
                  </p>
                </div>
                <img
                  src={
                    '/images_p/BecomePrimeTraderAmbassador/trader_vs_trader.webp'
                  }
                  alt='trader-vs-bull'
                  loading='eager'
                />
              </div>
            </div>
            <div className={style.content}>
              <div className={style.articleSection}>
                <img
                  src={'/images_p/BecomePrimeTraderAmbassador/trader_euro.webp'}
                  alt='trader-vs-bull'
                  loading='eager'
                />
                <div className={style.leftImage}>
                  <label>
                    NFT Vaults:
                    <br />
                    Unlock Digital Value
                  </label>
                  <p>
                    Dive into the world of NFTs with PrimeTrader’s NFT Vaults.
                    From rare art to virtual land, invest in high-value digital
                    assets with ease. Own a piece of the future as the digital
                    and physical worlds converge.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.content}>
              <div className={style.articleSection}>
                <label>
                  Crypto Funds:
                  <br />
                  Unleash Your Potential
                </label>
                <img
                  src={
                    '/images_p/BecomePrimeTraderAmbassador/trader_vs_bull.webp'
                  }
                  alt='trader-vs-bull'
                  loading='eager'
                />

                <p>
                  Access high-performing crypto funds curated by industry
                  experts and KOLs. Invest in dynamic strategies that power
                  growth—whether you’re seeking high-risk rewards or stable,
                  secure returns, PrimeTrader has the vault for you.
                </p>
              </div>
            </div>
            <div className={style.content}>
              <div className={style.articleSection}>
                <label>
                  Tokenized Real Estate:
                  <br />
                  Own the Future
                </label>

                <img
                  src={
                    '/images_p/BecomePrimeTraderAmbassador/trader_vs_trader.webp'
                  }
                  alt='trader-vs-trader'
                  loading='eager'
                />
                <p>
                  Invest in premium real estate across the world. From Dubai to
                  New York, own fractions of luxury properties and earn rental
                  income in PrimeTrader Tokens (PTT). Real estate is now at your
                  fingertips—accessible, secure, and global.
                </p>
              </div>
            </div>
            <div className={style.content}>
              <div className={style.articleSection}>
                <label>
                  NFT Vaults:
                  <br />
                  Unlock Digital Value
                </label>
                <img
                  src={'/images_p/BecomePrimeTraderAmbassador/trader_euro.webp'}
                  alt='trader-euro'
                  loading='eager'
                />
                <p>
                  Dive into the world of NFTs with PrimeTrader’s NFT Vaults.
                  From rare art to virtual land, invest in high-value digital
                  assets with ease. Own a piece of the future as the digital and
                  physical worlds converge.
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BecomePrimeTraderAmbassador;
