'use client';

import * as React from 'react';
import useEmblaCarousel, {
  type UseEmblaCarouselType
} from 'embla-carousel-react';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  selectedIndex: number;
  scrollTo: (index: number) => void;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    { orientation = 'horizontal', opts, setApi, plugins, children, ...props },
    ref
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y'
      },
      plugins
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
      setSelectedIndex(api.selectedScrollSnap());
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const scrollTo = React.useCallback(
      (index: number) => {
        api?.scrollTo(index);
      },
      [api]
    );

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on('reInit', onSelect);
      api.on('select', onSelect);

      return () => {
        api?.off('select', onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          selectedIndex,
          scrollTo
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          style={{ position: 'relative' }}
          role='region'
          aria-roledescription='carousel'
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  }
);
Carousel.displayName = 'Carousel';

const CarouselDots = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ ...props }, ref) => {
  const { selectedIndex, scrollTo, api } = useCarousel();
  console.log(selectedIndex);
  return (
    <div
      style={{
        position: 'absolute',
        bottom: '-4rem',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 50,
        marginTop: '1rem',
        display: 'flex',
        gap: '1rem'
      }}
      {...props}
    >
      {api?.scrollSnapList().map((_, index) => (
        <div
          key={index}
          style={{
            backgroundColor: index === selectedIndex ? '#A5EFFF' : '#000',
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '50%',
            cursor: 'pointer',
            border: '1px solid white'
          }}
          onClick={() => scrollTo(index)}
        />
      ))}
    </div>
  );
});
CarouselDots.displayName = 'CarouselDots';

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className='overflow-hidden'>
      <div
        ref={ref}
        style={{
          display: 'flex',
          gap: '30px',
          marginLeft: orientation === 'horizontal' ? '-1rem' : '0',
          marginTop: orientation === 'vertical' ? '-1rem' : '0',
          flexDirection: orientation === 'vertical' ? 'column' : 'row'
        }}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { flexBasis?: string }
>(({ flexBasis = '100%', ...props }, ref) => {
  const { orientation } = useCarousel();

  return (
    <div
      ref={ref}
      role='group'
      aria-roledescription='slide'
      style={{
        minWidth: 0,
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: flexBasis,
        paddingLeft: orientation === 'horizontal' ? '1rem' : '0',
        paddingTop: orientation === 'vertical' ? '1rem' : '0'
      }}
      {...props}
    />
  );
});
CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = React.forwardRef<HTMLButtonElement>(
  ({ ...props }, ref) => {
    const { scrollPrev, canScrollPrev } = useCarousel();

    return (
      <button
        ref={ref}
        style={{
          position: 'absolute',
          height: '2rem',
          width: '2rem',
          borderRadius: '9999px',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '-2rem'
        }}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...props}
      >
        <span>{`<`}</span>
        <span className='sr-only'>Previous slide</span>
      </button>
    );
  }
);
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = React.forwardRef<HTMLButtonElement>(
  ({ ...props }, ref) => {
    const { scrollNext, canScrollNext } = useCarousel();

    return (
      <button
        ref={ref}
        style={{
          position: 'absolute',
          height: '2rem',
          width: '2rem',
          borderRadius: '9999px',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '-2rem'
        }}
        disabled={!canScrollNext}
        onClick={scrollNext}
        {...props}
      >
        <span>{`>`}</span>
        <span className='sr-only'>Next slide</span>
      </button>
    );
  }
);
CarouselNext.displayName = 'CarouselNext';

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDots
};
