import React from 'react';
import style from './style.module.scss';

const AiPoweredTrading = () => {
  return (
    <section className={style.aiPoweredTrading}>
      <div className={style.sectionContent}>
        <div className={style.leftContent}>
          <label>
            AI-Powered Trading, <br />
            Just Say <br />
            "Hey Trader"
          </label>
          <p>
            PrimeTrader transforms the way you invest with your own AI-driven
            Virtual Trading Assistant (VTA) and coach. Each trader has a
            personalized avatar that guides you through the platform, delivers
            real-time market updates, and executes trades—all with a simple
            voice command. Say “Hey Trader,” and your VTA handles the rest,
            making trading smarter, faster, and effortless.
          </p>
        </div>
        <div className={style.rightContent}>
          <img
            src='/images_p/AiPoweredTrading/chat_box.webp'
            alt='newmob'
            loading='eager'
          />
        </div>
      </div>
    </section>
  );
};

export default AiPoweredTrading;
