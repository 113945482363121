interface TestimonialProps {
  id: number;
  name: string;
  title: string;
  quote: string;
  imageSrc: string;
}

export const testimonials: TestimonialProps[] = [
  {
    id: 1,
    name: 'Jose Zamorano',
    title: 'Strategy Advisor',
    imageSrc: '/images/testimonials/jose.png',
    quote:
      'The team at PrimeTrader has a deep understanding of both traditional finance and cutting-edge blockchain technology. Their strategic vision is unparalleled, and their dedication to democratizing financial tools through crypto is inspiring. They are set to revolutionize the way we think about asset management in the digital age.'
  },
  {
    id: 2,
    name: 'Dr. Andrea Bracciali',
    title: 'Blockchain Advisor',
    imageSrc: '/images/testimonials/andrea.png',
    quote:
      'I am impressed by how PrimeTrader leverages blockchain and the latest innovations in the space to create a seamless onboarding experience for users. Their mission to simplify DeFi and Web3 is a true reflection of the future of decentralized platforms.'
  }
  // {
  //   id: 3,
  //   name: 'Dr. Reto Luthiger',
  //   title: 'Blockchain Legal Advisor',
  //   imageSrc: '/images/testimonials/reto.png',
  //   quote:
  //     'Navigating the regulatory environment in the blockchain space can be daunting, but PrimeTrader balances innovation with regulatory foresight so effectively. Their commitment to compliance, transparency and diligence provides users with the confidence they need to engage fully with crypto.'
  // },
  // {
  //   id: 4,
  //   name: 'Emma Thompson',
  //   title: 'DeFi Specialist',
  //   imageSrc: '/placeholder.svg?height=64&width=64',
  //   quote:
  //     "PrimeTrader's approach to decentralized finance is revolutionary. They've managed to simplify complex DeFi concepts without compromising on functionality or security. It's exciting to see how they're bridging the gap between traditional and decentralized finance."
  // },
  // {
  //   id: 5,
  //   name: 'Michael Chen',
  //   title: 'Crypto Economics Expert',
  //   imageSrc: '/placeholder.svg?height=64&width=64',
  //   quote:
  //     "The economic models underlying PrimeTrader's platform are robust and well-thought-out. They've clearly put a lot of effort into ensuring the sustainability and scalability of their ecosystem. It's a prime example of how crypto economics should be applied in real-world projects."
  // }
];
