import React from 'react';

const EcoSystem = ({ isMobileView }: any) => {
  // const echoSystemImgRef = useRef(null);

  // useEffect(() => {
  //   console.log('--scroll');
  //   if (echoSystemImgRef.current) {
  //     const imageWidth = echoSystemImgRef.current.offsetWidth;
  //     console.log('--scroll', imageWidth);

  //     const containerWidth = window.innerWidth;
  //     console.log('--scroll', containerWidth);

  //     const scrollPosition = imageWidth * 2 - containerWidth * 1;
  //     console.log('--scroll', scrollPosition);

  //     // Scroll the container element horizontally to the calculated scroll position
  //     window.scrollTo({
  //       left: scrollPosition,
  //       behavior: 'smooth' // You can change this to 'auto' for instant scrolling
  //     });
  //   }
  // }, []);

  return (
    <>
      {isMobileView ? (
        <section className='mob-pt-eco-system-section'>
          <div className='liner-shade'>
            <div className='mob-container'>
              <label>PrimeTrader Protocol</label>
              <div className='scroll-container'>
                <div className='scroll-content'>
                  <img
                    src={'/images_p/pt-eco-system-img-updated.webp'}
                    alt='pt-eco-system-img'
                    className='responsive pt-responsive'
                    loading='eager'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className='pt-eco-system-section' id='protocol'>
          <div className='liner-shade'>
            <div className='container'>
              <label>PrimeTrader Protocol</label>
              {!isMobileView && (
                <img
                  src={'/images_p/pt-eco-system-img-updated.webp'}
                  alt='pt-eco-system-img'
                  className='responsive pt-responsive'
                  loading='eager'
                />
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EcoSystem;
