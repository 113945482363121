import React, { useEffect, useState } from 'react';
import style from './style.module.scss';

const PrimeTraderApp = () => {
  const [isMobileView, setIsMobileView] = useState(
    window.matchMedia('(max-width: 1100px)').matches ? true : false
  );

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1100) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
      document.body.style.visibility = 'visible';
    }
    // handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {!isMobileView ? (
        <>
          <section className={style.primeTraderAppSection}>
            <div className={style.sectionContent}>
              <div className={style.leftContent}>
                <img
                  src={'/images_p/PrimeTraderApp/mobile_store.webp'}
                  alt='mobile-store'
                  loading='eager'
                />
              </div>
              <div className={style.rightContent}>
                <label>PrimeTrader App</label>
                <p>Now available in App Store and Google Pay</p>
                <div className={style.linksContainer}>
                  <a href='https://www.apple.com/app-store/'>
                    <img
                      src={'/images_p/PrimeTraderApp/appstore.webp'}
                      alt='mobile-store'
                      loading='eager'
                    />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.primetrader&pli=1'>
                    <img
                      src={'/images_p/PrimeTraderApp/googleplay.webp'}
                      alt='mobile-store'
                      loading='eager'
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className={style.primeTraderAppSection}>
            <div className={style.mobileSectionContent}>
              <div className={style.topSection}>
                <div className={style.headerSection}>
                  <label>PrimeTrader App</label>
                  <p>Now available in App Store and Google Pay</p>
                </div>
                <img
                  src={'/images_p/PrimeTraderApp/mobile_store.webp'}
                  alt='mobile-store'
                  loading='eager'
                />
              </div>
              <div className={style.linksContainer}>
                <a href='https://www.apple.com/app-store/'>
                  <img
                    src={'/images_p/PrimeTraderApp/appstore.webp'}
                    alt='mobile-store'
                    loading='eager'
                  />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.primetrader&pli=1'>
                  <img
                    src={'/images_p/PrimeTraderApp/googleplay.webp'}
                    alt='mobile-store'
                    loading='eager'
                  />
                </a>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default PrimeTraderApp;
