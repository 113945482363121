import styles from './styles.module.scss';
import React, { useState } from 'react';


const FAQtemp = [
  {
    title: 'How does PrimeTrader make investing easier for new users?',
    text: 'PrimeTrader is designed with simplicity in mind. Our Smart Wallet enables one-click access to Web3, while our AI-powered Virtual Trading Assistant (VTA) guides you through every step—from depositing funds to making your first investment. Whether you’re a beginner or a seasoned pro, we make investing effortless.'
  },
  {
    title: 'What types of assets can I invest in on PrimeTrader?',
    text: 'With PrimeTrader, you can invest in a diverse range of assets, including top-performing crypto funds, tokenized real estate, gold, and other real-world assets. Our platform also offers co-investment opportunities with industry experts and access to high-potential portfolios managed by key opinion leaders (KOLs).'
  },
  {
    title: 'How do I earn rewards and income on PrimeTrader?',
    text: 'PrimeTrader offers multiple ways to earn. You can stake assets like gold and earn up to 10% APY in rewards, invest in real estate to receive rental income in PrimeTrader Tokens (PTT), and participate in performance-based bonuses through our expert-managed funds.'
  },
  {
    title: 'How does PrimeTrader ensure the security of my funds?',
    text: 'PrimeTrader operates under a VASP license, ensuring compliance with global financial regulations. We provide secure self-custodial wallets, giving you full ownership and control of your assets. All transactions are transparent and protected by blockchain technology, offering top-tier security.'
  }
];
export const FAQ = () => {

  const [opened, setOpen] = useState(0);

  return <div className={styles.faqSection}>
    {FAQtemp.map((item, index) => <div key={index}
                                       className={`${styles.cardWrapper} ${index === opened ? styles.openedCard : styles.closedCard}`}>

      <div className={styles.flexRow} onClick={() => setOpen(index)}>
        <p className={styles.title}>
          {item.title}
        </p>
        <img
          src={`/images/${opened === index ? 'minus' : 'plus'}.svg`}
          alt='prime-trader-logo'
          width={'45px'}
        />
      </div>
      {opened === index && <p className={styles.text}>{item.text}</p>}
    </div>)}
  </div>;
};