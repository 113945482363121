'use client';

import { TestimonialCard } from '@/components/Testimonials/testimonial-card';
import { testimonials } from '@/components/Testimonials/testiomnials';
import React, { useState, useCallback, useEffect, CSSProperties } from 'react';
import { useSwipeable } from 'react-swipeable';

const containerStyle: CSSProperties = {
  position: 'relative',
  width: '100%',
  maxWidth: '1152px',
  height: '600px',
  margin: '0 auto',
  overflow: 'hidden',
  borderRadius: '0.75rem',
  padding: '2rem',
  transform: 'translateY(-10%)'
};

const cardContainerStyle: CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%'
};

const sliderContainerStyle: CSSProperties = {
  position: 'absolute',
  bottom: '2rem',
  left: '0',
  right: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem'
};

const sliderStyle: CSSProperties = {
  width: '300px',
  height: '6px',
  background: 'rgba(57, 62, 101, 0.50)',
  borderRadius: '60px',
  overflow: 'hidden'
};

const sliderProgressStyle: CSSProperties = {
  height: '100%',
  background: 'linear-gradient(90deg, #45D3E8 0%, #602EA6 105.38%)',
  transition: 'width 0.3s ease-in-out'
};
export default function MobileTestimonials()  {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextCard = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  }, []);

  const prevCard = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: nextCard,
    onSwipedRight: prevCard,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        prevCard();
      } else if (event.key === 'ArrowRight') {
        nextCard();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [nextCard, prevCard]);

  return (
    <div style={containerStyle} {...handlers}>
      <div style={cardContainerStyle}>
        {testimonials.map((card, index) => {
          const offset =
            (index - currentIndex + testimonials.length) % testimonials.length;
          const isVisible = offset >= 0 && offset < 3;

          const cardStyle: CSSProperties = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: testimonials.length - offset,
            transform: `
              translate(-50%, -50%)
              translate(${offset * 40}px, ${offset * 40}px)
              scale(${1 - offset * 0.1})
            `,
            opacity: isVisible ? 1 - offset * 0.3 : 0,
            transition: 'all 0.5s ease-in-out',
            pointerEvents: offset === 0 ? 'auto' : 'none'
          };

          return (
            <div key={card.id} style={cardStyle}>
              <TestimonialCard key={index} {...card} />
            </div>
          );
        })}
      </div>
      <div style={sliderContainerStyle}>
        <div style={sliderStyle}>
          <div
            style={{
              ...sliderProgressStyle,
              width: `${((currentIndex + 1) / testimonials.length) * 100}%`
            }}
          />
        </div>
      </div>
    </div>
  );
}
